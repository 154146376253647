import * as React from "react"

import {graphql, Link, useStaticQuery} from 'gatsby'
import Layout from '../../components/layout/layout'
import parse from 'html-react-parser'
import Mediapost from "../../components/mediapost/mediapost"


const VideoDetailPage = ({pageContext}) => {
    const {post} = pageContext

    var pageTitle = ''
    if (post) {
        pageTitle = `Agile Hobby: ${post.title_en}`
    }

    return (
        <Layout pageTitle={pageTitle}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 pt-4 mb-5">
                        {post ?
                            (
                                <>
                                    <h1 className="mb-3">Videos 視頻</h1>

                                    <h2 className="mb-3 orangeTitle"><Link
                                        to={`/videos/${post.slug}/`}>{post.title_en}</Link></h2>

                                    {(new Date(post.creation_date_timestamp * 1000)).toLocaleDateString()}


                                    <div className="embed-responsive embed-responsive-16by9 mb-3">
                                        <div className="ratio ratio-16x9">
                                            <iframe src={`https://www.youtube.com/embed/${post.slug}`}
                                                    title="YouTube video"
                                                    allowFullScreen></iframe>
                                        </div>
                                    </div>

                                    {post.summary_en}
                                </>
                            )
                            : null}
                    </div>
                </div>

            </div>

        </Layout>

    )
}

export default VideoDetailPage
